import { useCallback, useMemo, useState } from 'react';
import AppLayout from '../components/layout/AppLayout';
import useProductosFetch from '../hooks/useProductosFetch';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router';
// import useFetchAlmacen from '../hooks/useFetchAlmacen';
import { orderBy } from 'lodash';
import useImagenesFetch from '../hooks/useImagenesFetch';
import { Image } from 'primereact/image';
import { InputSwitch } from 'primereact/inputswitch';
import { WS_IMG_URI } from '../services/commonHelper';

const ProductosPage = () => {
	const { productos, isLoadingProductos } = useProductosFetch();
	const { imagenes } = useImagenesFetch({});
	// const { estadoAlmacen: almacenZ, isLoadingEstadoAlmacen } = useFetchAlmacen({ id: 1 });
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});

	const [stock, setStock] = useState(false);

	const navigate = useNavigate();
	
	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };
		_filters['global'].value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	};
	const renderHeader = () => {
		return (
			<div className='flex justify-content-end'>
				<span className='p-input-icon-left'>
					<i className='pi pi-search' />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder='Buscar nombre/zona..' />
				</span>
			</div>
		);
	};

	const renderEdit = useCallback(
		(row) => (
			<>
				<Button
					icon='pi pi-pencil'
					tooltip='Editar producto'
					className='ml-2'
					onClick={() => {
						navigate(`/producto/${row.id}`);
					}}
				/>
				<Button
					icon='pi pi-box'
					tooltip='Nuevo movimiento'
					className='ml-2'
					onClick={() => {
						navigate(`/nuevoMovimiento?producto=${row.codigoP}&entrada=false`);
					}}
				/>
				
			</>
		),
		[navigate]
	);

	const renderImage = useCallback(
		(row)=>{
		const imagesP = imagenes?.filter(i=>i.producto===row.id);
		if(imagesP?.length){
			return <Image src={`${WS_IMG_URI}/${imagesP[0].id}`} alt='Image' height='60' preview key={imagesP[0].id} />;
		}
		return null;
	},[imagenes]);

	const datos = useMemo(
		() =>
			productos?.map((p) => ({
				...p,
				almacenado: p.stock ?? 0,
				imagenes: imagenes?.filter((i) => i.producto === p.id)?.length ?? 0,
			}))?.filter(p=>stock?p?.almacenado>0: true),
		[ imagenes, productos, stock]
	);

	return (
		<AppLayout>
			<h4 className='p-0 m-0'>
				Listado productos
				<Button
					icon='pi pi-plus'
					rounded
					size='small'
					aria-label='Nuevo'
					tooltip='Nuevo producto'
					className='ml-2'
					onClick={() => {
						navigate('/nuevoProducto');
					}}
				/>
				Solo almacenado
				<InputSwitch checked={stock} onChange={(e) => setStock(e.value)} />
			</h4>

			<div className='grid align-items-center justify-content-center  m-0 p-0'>
				<div className='col-12 p-1'>
					<DataTable
						scrollable
						scrollHeight='calc(100vh - 270px)'
						paginator
						rows={50}
						rowsPerPageOptions={[50, 100, 150, 200]}
						loading={isLoadingProductos }
						value={orderBy(datos, ['almacenado'], ['desc'])}
						stripedRows
						size='small'
						filters={filters}
						globalFilterFields={['codigoP', 'nombre']}
						header={renderHeader}
					>
						<Column header='Id' field='id' style={{ width: '90px' }} />
						<Column header='' body={renderImage} />
						<Column header='Ean' field='codigoP' style={{ width: '120px' }} sortable></Column>
						<Column header='Nombre' field='nombre' sortable></Column>
						<Column header='Pvp' field='pvp' style={{ width: '150px' }} sortable></Column>
						<Column header='Pvp Oferta' field='pvpOferta' style={{ width: '150px' }} sortable></Column>
						<Column header='Almacenado' field='almacenado' style={{ width: '150px' }} sortable></Column>
						<Column header='' body={renderEdit} style={{ width: '170px' }} sortable></Column>
					</DataTable>
				</div>
			</div>
		</AppLayout>
	);
};

export default ProductosPage;
